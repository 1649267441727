import { render, staticRenderFns } from "./basic.vue?vue&type=template&id=e49cd530&"
import script from "./basic.vue?vue&type=script&lang=js&"
export * from "./basic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Toast: require('/codebuild/output/src4030858449/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/toast/index.vue').default})
